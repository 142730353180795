import React, { useEffect, useLayoutEffect, useRef } from "react";
import Features from "../Features/Features";

import rightStatue from "../../assets/img/right-statue.png";
import sttBigText from "../../assets/img/stt-big-text.png";
import LeftStatue from "../LeftStatue/LeftStatue";
import WhySTT from "../WhySTT/WhySTT";
import IconSection from "../IconSection/IconSection";
import GetStarted from "../GetStarted/GetStarted";
import ReserveAUnit from "../ReserveAUnit/ReserveAUnit";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import tl from "../../utils/transition";

import fourBarLines from "../../assets/img/four-bar-lines.svg";

gsap.registerPlugin(ScrollTrigger);

const LongSection = () => {
  useLayoutEffect(() => {
    tl.fromTo(
      ".big-stt-img",
      {
        opacity: 0,
        scale: 0.2,
        yPercent: 100,
        ease: "power2.out",
      },
      {
        opacity: 1,
        scale: 1,
        yPercent: 0,
        scrollTrigger: {
          trigger: ".long-section-top",
          start: "top 80%",
          end: "10% bottom",
          scrub: true,
        },
      }
    );
    tl.fromTo(
      ".big-stt-text",
      {
        opacity: 0,
        yPercent: 100,
        ease: "power2.out",
      },
      {
        opacity: 1,
        yPercent: 0,
        scrollTrigger: {
          trigger: ".long-section-top",
          start: "top 80%",
          end: "10% bottom",
          scrub: true,
        },
      }
    );
    tl.fromTo(
      ".right-statue",
      {
        opacity: 0,
        scale: .5,
        xPercent: 100,
        ease: "power2.out",
      },
      {
        opacity: 1,
        scale: 1,
        xPercent: 0,
        scrollTrigger: {
          trigger: ".long-section-top",
          start: "top 80%",
          end: "10% bottom",
          scrub: true,
        },
      }
    );
  }, []);

  return (
    <section className='long-section relative flex flex-col w-screen h-auto mx-auto justify-center items-center lg:pt-[8rem] long-section-top overflow-x-hidden'>
      <div className='w-1/2 md:w-1/3 flex flex-col items-center pt-4 relative'>
        <p className='lg:block text-white uppercase text-[7px] lg:text-2xl font-inter big-stt-text  ml-[30%] lg:ml-[35%]'>
          Africa's Tallest Twin Tower
        </p>
        <img
          src={sttBigText}
          alt='stt-big-text'
          className='w-[90%] lg:w-full big-stt-img'
        />
      </div>

      {/* Four Bar Lines */}
      <img
        src={fourBarLines}
        alt='four-bar-lines'
        className='absolute top-0 left-0 right-0 ml-auto mr-auto four-bar-lines  '
      />

      {/* Statues */}
      <div className='right-statue absolute top-8 lg:top-0 right-0 ml-[16rem] md:ml-[35rem] lg:ml-0 pr-5 lg:pr-0'>
        <img
          src={rightStatue}
          alt='right-statue'
          className='w-full lg:mt-0 mt-[5rem]'
        />
      </div>

      <LeftStatue />
      <Features />
      <WhySTT />
      <IconSection />
      <ReserveAUnit />
      <GetStarted />
    </section>
  );
};

export default LongSection;
