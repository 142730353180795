import React, { useLayoutEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import leftImage from "../../assets/img/reserve-a-unit-left-img.svg";
import rightImage from "../../assets/img/reserve-a-unit-right-img.svg";
import tl from "../../utils/transition";
import { scrollToEl } from "../../features/layout/layoutSlice";

gsap.registerPlugin(ScrollTrigger);

const ReserveAUnit = () => {
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    tl.fromTo(
      ".reserve-left-img",
      {
        x: "5rem",
        ease: "power2.out",
      },
      {
        x: "18rem",
        scrollTrigger: {
          trigger: ".reserve-container",
          start: "10% bottom",
          // end: "30% 70%",
          scrub: true,
        },
      }
    );
    tl.fromTo(
      ".reserve-right-img",
      {
        x: "-5rem",
        ease: "power2.out",
      },
      {
        x: "-18rem",
        scrollTrigger: {
          trigger: ".reserve-container",
          start: "10% bottom",
          // end: "80% bottom",
          scrub: true,
        },
      }
    );
    tl.fromTo(
      ".center-img-wrapper-after",
      {
        opacity: 0,
        yPercent: 5,
        ease: "power2.out",
      },
      {
        opacity: 1,
        yPercent: 0,
        scrollTrigger: {
          trigger: ".reserve-container",
          start: "10% bottom",
          end: "80% bottom",
          scrub: true,
        },
      }
    );
    tl.fromTo(
      ".reserve-highlights",
      {
        opacity: 0,
        yPercent: 10,
        ease: "power2.out",
      },
      {
        opacity: 1,
        yPercent: 0,
        scrollTrigger: {
          trigger: ".reserve-container",
          start: "30% bottom",
          end: "80% bottom",
          scrub: true,
        },
      }
    );
  });
  const highlights = [
    {
      title: "12%-15% return on Hotel Investment Per Annum",
    },
    {
      title: "200% ROI on Equity",
    },
    {
      title: "Exceptional Features and Amenities",
    },
    {
      title: "First of its kind, 3-in-one Unique iconic building",
    },
    {
      title: "Nigeria’s Largest Luxury Retail & Entertainment Destination",
    },
  ];
  const leftImgRef = useRef();
  const rightImgRef = useRef();
  const centerImgRef = useRef();
  const ulHighlightsRef = useRef();

  return (
    <div className=' container flex flex-col justify-center items-center space-y-32 pt-20 reserve-container py-1 mb-[5rem] lg:mb-[10rem]'>
      {/* <div className='w-full h-[1px] bg-white lg:w-[75%] lg:h-[1px]'></div> */}
      <h1
        className='font-koh-santepheap text-center text-5xl my-3 text-white lg:text-[7rem] lg:text-start'
        // style={{ marginTop: "0" }}
      >
        Why Invest?
      </h1>
      {/* <div className='w-full h-[1px] bg-white lg:w-[75%] lg:h-[1px]'></div> */}
      <div className='flex justify-center items-end reserve-hotspot cursor-pointer'>
        <div>
          <img
            src={leftImage}
            alt='left-img'
            className='scale-[.9] reserve-left-img relative'
            ref={leftImgRef}
          />
        </div>
        <div className='z-10 relative h-[280px] lg:h-[550px] w-[550px] lg:w-[400px] center-img-wrapper overflow-hidden'>
          <div className='center-img-wrapper-after'></div>
          <ul
            className='text-white absolute text-inter lg:text-1xl z-20 mb-auto mt-auto mr-auto ml-auto left-0 right-0 bottom-0 top-[5%] lg:top-[25%] flex flex-col space-y-2 lg:space-y-5 w-full lg:w-[80%] px-3 reserve-highlights'
            ref={ulHighlightsRef}
          >
            {highlights.map((highlight, index) => (
              <li key={index} className='font-inter text-sm'>
                <div className='flex flex-col space-y-1 lg:space-y-5'>
                  <p className='text-[12px] lg:text-1xl'>{highlight.title}</p>
                  <hr />
                </div>
              </li>
            ))}
          </ul>
        </div>

        <div>
          <img
            src={rightImage}
            alt='right-img'
            className='md:scale-[1] lg:scale-[.9] reserve-right-img'
            ref={rightImgRef}
          />
        </div>
      </div>

      <button
        className='text-white text-sm font-inter uppercase border border-white px-12 py-2 reserve-a-unit-btn cursor-pointer'
        onClick={() => dispatch(scrollToEl())}
      >
        Get Started
      </button>
      <p className='text-white font-inter text-2xl w-full md:w-[35%] text-center leading-10'>
        Your ultimate opportunity to <span className='text-themeOrange'>unlock</span> an
        ultra-luxury living experience and a
        <span className='text-themeOrange'> lifetime</span> of wealth{" "}
        <span className='text-themeOrange'> creation</span>.
      </p>
    </div>
  );
};

export default ReserveAUnit;
