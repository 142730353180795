import React, { useLayoutEffect } from "react";
import icons from "../../utils/icons";
import tl from "../../utils/transition";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const IconSection = () => {
  useLayoutEffect(() => {
    tl.fromTo(
      ".icon",
      {
        opacity: 0,
        yPercent: 100,
        ease: "power2.out",
      },
      {
        opacity: 1,
        yPercent: 0,
        stagger: 0.2,
        scrollTrigger: {
          trigger: ".icons",
          start: "10% bottom",
          end: "80% 80%",
          scrub: true,
        },
      }
    );
  });
  return (
    <div className='container py-10 w-screen h-full flex justify-center items-center icons-section__container icons'>
      {icons.map((icon, index) => (
        <div className='flex flex-col items-center space-y-3 icon' key={index}>
          <img src={icon.icon} alt='icon-img' className='scale-[.8]' />
          <p className='text-xs text-white font-inter uppercase w-1/2 text-center'>
            {icon.title}
          </p>
        </div>
      ))}
    </div>
  );
};

export default IconSection;
