import gsap from "gsap";

const tl = gsap.timeline({
  defaults: {
    duration: 1,
    ease: "back.config(2)",
  },
});

export default tl;
