import React, { useEffect, useLayoutEffect, useRef } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";

import { FiFacebook, FiLinkedin, FiYoutube } from "react-icons/fi";

import logoWhite from "../../assets/img/logo-white.png";
import logoWithText from "../../assets/img/STT-logo-03.svg";

const Nav = () => {
  const logoRef = useRef(null);
  const middleTextRef = useRef(null);

  useLayoutEffect(() => {
    const tl = gsap.timeline({
      defaults: {
        duration: 1,
        ease: "back.config(2)",
      },
    });

    tl.from(logoRef.current, {
      opacity: 0,
      xPercent: -100,
    });

    tl.from(
      middleTextRef.current,
      {
        opacity: 0,
        yPercent: -100,
      },
      "-=.5"
    );

    tl.from(
      ".social-link",
      {
        opacity: 0,
        yPercent: -100,
        stagger: 0.1,
      },
      "-=.5"
    );
  }, []);

  return (
    <nav className='relative w-full lg:w-[90%] flex justify-between items-center mx-auto px-3 md:px-10 py-5'>
      <div className='w-[15%] md:w-[10%] lg:w-[6%]'>
        <img
          src={logoWithText}
          alt='logo-whites'
          className='md:ml-0'
          ref={logoRef}
        />
      </div>
      {/* <p className='text-[.6rem] text-white font-koh-santepheap'>
          Sujimoto Twin Tower
        </p> */}
      <h1
        className='hidden text-1xl md:text-sm lg-text-1xl font-koh-santepheap text-white text-center lg:text-start md:block'
        ref={middleTextRef}
      >
        <span className='text-themeOrange'>Nigeria’s</span> 1st{" "}
        <span className='text-themeOrange'>of its kind,</span> 3-
        <span className='text-themeOrange'>in</span>
        -1 Building.
      </h1>
      <div className='flex space-x-5 items-center justify-between'>
        <a
          className='text-white social-link'
          href='https://www.facebook.com/motoconstructionng'
          target='_blank'
          rel='noreferrer'
        >
          <FiFacebook color='#fff' size={18} />
        </a>
        <a
          className='text-white social-link'
          href='https://www.linkedin.com/company/sujimoto-construction-limited'
          target='_blank'
          rel='noreferrer'
        >
          <FiLinkedin color='#fff' size={18} />
        </a>
        <a
          className='text-white social-link'
          href='https://www.youtube.com/channel/UC1Q3PEh495SxXY-npjegTkg'
          target='_blank'
          rel='noreferrer'
        >
          <FiYoutube color='#fff' size={18} />
        </a>
      </div>
    </nav>
  );
};

export default Nav;
