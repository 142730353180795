import React, { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { scrollToEl } from "../../features/layout/layoutSlice";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import tl from "../../utils/transition";

import pulser from "../../assets/img/pulser.svg";

const UniqueKeyFacts = () => {
  const dispatch = useDispatch();

  gsap.registerPlugin(ScrollTrigger);

  useLayoutEffect(() => {
    tl.fromTo(
      ".unique-key-fact",
      {
        opacity: 0,
        yPercent: 100,
        ease: "power2.out",
      },
      {
        opacity: 1,
        yPercent: 0,
        scrollTrigger: {
          trigger: ".uniquekeyfacts",
          start: "top bottom",
          end: "bottom 50%",
          scrub: true,
        },
      }
    );
  });

  const uniqueFacts = [
    {
      title: "1st of its kind, 3-in-1",
      leading:
        "STT Provides an unprecedented hyper-Luxury living experience. Nigeria’s first-of-its-kind 3-in-1 edifice that represents living canvases of residences, hotel apartments, and Luxury retail in one unique space.",
      more: "hyper-luxury living and best hotel experience while bringing the world to the doorstep of its guests. At STT, the everyday need of the guest and residences will be accessed within a 2 minutes’ walk. Live. Work and Play",
    },
    {
      title: "One-Stop-Shop",
      leading:
        "STT is a one-stop-shop for all. A unique destination that captures a perfect blend of hyper-luxury living and the best hotel experience while bringing the world to the doorstep of its guests. At STT, the everyday need of the guest and residents will be accessed within a 2 minutes walk. - Live. Work and Play.",
      more: "A residential and hotel space pulsating with world leading brands including six celebrity restaurants within it.",
    },
    {
      title: "40+ Global Retail Brands",
      leading:
        "STT is set to become Nigeria’s Largest Luxury Retail & Entertainment Destination. A residential and hotel space pulsating with world-leading brands including six celebrity restaurants within it.",
      more: "A residential and hotel space pulsating with world leading brands including six celebrity restaurants within it.",
    },
  ];
  return (
    <div className='absolute top-[3%] left-0 w-screen flex justify-center'>
      <div className='uniquekeyfacts w-[100%] flex relative mt-8 lg:mt-0'>
        <div className='flex space-x-4 first left-[-10rem ] items-start justify-self-start unique-key-fact'>
          <img src={pulser} alt='pulser' className='w-[20px] lg:w-[54px]' />
          <div className='flex flex-col first-ofits-kind'>
            <h4 className='font-koh-santepheap text-white text-[10px] lg:text-2xl font-bold w-[30%] lg:w-[40%]'>
              1st of its kind, three-in-one
            </h4>
            <p className='text-white text-[8px] lg:text-sm font-koh-santepheap w-[40%] lg:w-[50%]'>
              STT is a one-stop-shop for all. A unique destination that captures
              a perfect blend of
            </p>
            <a
              href='#!'
              className='text-[8px] lg:text-sm text-themeOrange font-koh-santepheap'
            >
              Read more
            </a>
          </div>
        </div>
        <div className='flex items-start space-x-4 second justify-self-center unique-key-fact'>
          <img src={pulser} alt='pulser' className='w-[20px] lg:w-[54px]' />
          <div className='flex flex-col'>
            <h4 className='font-koh-santepheap text-white text-[10px] lg:text-2xl font-bold w-[50%] lg:w-[45%]'>
              One-Stop-Shop 2 Minutes to Everything
            </h4>
            <p className='text-white text-[8px] lg:text-sm font-koh-santepheap w-[50%] lg:w-[55%]'>
              STT is set to become Nigeria’s Largest Luxury Retail &
              Entertainment Destinations.
            </p>
            <a
              href='#!'
              className=' text-[8px] lg:text-sm text-themeOrange font-koh-santepheap'
            >
              Read more
            </a>
          </div>
        </div>
        <div className='flex flex-col third justify-end lg:-mt-[15rem] items-start justify-self-end unique-key-fact '>
          <div className='flex flex-col'></div>
          <h2 className='text-white font-inter font-extrabold -mt-[15rem] lg:-mt-0 uppercase text-[11px] lg:text-3xl w-1/3 text-right'>
            Unique Key Facts
          </h2>
          <div className='flex items-start space-x-4 forty mt-[16rem] lg:mt-[10rem] mb-[1.3rem] lg:mb-[5rem] w-[100%] pr-[-5rem]'>
            <img src={pulser} alt='pulser' className='w-[20px] lg:w-[54px]' />

            <div className='flex flex-col'>
              <h4 className='font-koh-santepheap text-white text-[12px] lg:text-2xl font-bold w-[45%] '>
                40+ Global Retail Brands
              </h4>
              <p className='text-white text-[8px] lg:text-sm font-koh-santepheap w-[55%] lg:w-[50%]'>
                STT is set to become Nigeria’s Largest Luxury Retail &
                Entertainment Destination.
              </p>
              <a
                href='#!'
                className='text-[8px] lg:text-sm font-koh-santepheap text-themeOrange'
              >
                Read more
              </a>
            </div>
          </div>
          <button
            className='uppercase text-white px-[10px] lg:px-12 py-[6px] text-[10px] lg:text-sm font-inter bg-transparent border border-white  bottom-0 right-[15%] ml-[5rem]'
            onClick={() => dispatch(scrollToEl())}
          >
            Book Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default UniqueKeyFacts;
