import homeAutomation from '../assets/img/icons/home-automation.svg';
import carPark from '../assets/img/icons/car-park.svg';
import creche from '../assets/img/icons/creche.svg';
import elevator from '../assets/img/icons/elevator.svg';
import glassReinforced from '../assets/img/icons/glass-reinforced.svg';
import greenAreas from '../assets/img/icons/green-areas.svg';
import gym from '../assets/img/icons/gym.svg';
import hangingPool from '../assets/img/icons/hanging-pools.svg';
import interactiveLobby from '../assets/img/icons/interactive-lobby.svg';
import playArea from '../assets/img/icons/play-area.svg';
import privateCinema from '../assets/img/icons/private-cinema.svg';
import privateStorage from '../assets/img/icons/private-storage.svg';
import tallResidences from '../assets/img/icons/residences.svg';
import swimmingPool from '../assets/img/icons/swimming-pool.svg';
import virtualGolf from '../assets/img/icons/virtual-golf.svg';
import woodenEffect from '../assets/img/icons/wooden-effect.svg';
import zahaHadid from '../assets/img/icons/zaha-hadid-bathroom.svg';

const icons = [
  {
    title: "Residences",
    icon: tallResidences,
  },
  {
    title: "Hotel",
    icon: glassReinforced,
  },
  {
    title: "Retail",
    icon: woodenEffect,
  },
  {
    title: "Full Home Automation",
    icon: homeAutomation,
  },
  {
    title: "Virtual Golf Bar",
    icon: virtualGolf,
  },
  {
    title: "6 Swimming Pools",
    icon: swimmingPool,
  },
  {
    title: "Hanging Pools",
    icon: hangingPool,
  },
  {
    title: "Luxury Beds",
    icon: interactiveLobby,
  },
  {
    title: "Private Cinema",
    icon: privateCinema,
  },
  {
    title: "Creche",
    icon: creche,
  },
  {
    title: "Elevator",
    icon: elevator,
  },
  {
    title: "4m Floor to Ceiling",
    icon: homeAutomation,
  },
  {
    title: "Play Area",
    icon: playArea,
  },
  {
    title: "TechnoGym",
    icon: gym,
  },
  {
    title: "Zaha Hadid Bathroom",
    icon: zahaHadid,
  },
  {
    title: "Car Park",
    icon: carPark,
  },
  {
    title: "Green Areas",
    icon: greenAreas,
  },
  {
    title: "Private Storage",
    icon: privateStorage,
  },
  {
    title: "Glass Reinforced Wall",
    icon: glassReinforced,
  },
  {
    title: "Wooden Effect",
    icon: woodenEffect,
  },
];

export default icons;