import React, { useLayoutEffect } from "react";
import leftStatue from "../../assets/img/left-statue.png";
import UniqueKeyFacts from "../UniqueKeyFacts/UniqueKeyFacts";

import { useDispatch } from "react-redux";
import experienceBtn from "../../assets/img/experience-btn.svg";
import mapOutline from "../../assets/img/map-outline.png";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import tl from "../../utils/transition";

import { scrollToEl } from "../../features/layout/layoutSlice";

gsap.registerPlugin(ScrollTrigger);

const LeftStatue = () => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    tl.fromTo(
      ".left-statue",
      {
        opacity: 0,
        scale: 0.5,
        xPercent: -100,
        ease: "power2.out",
      },
      {
        opacity: 1,
        scale: 1,
        xPercent: 0,
        scrollTrigger: {
          trigger: ".left-statue-top",
          start: "40% bottom",
          end: "40% 60%",
          scrub: true,
        },
      }
    );
    tl.fromTo(
      ".map-outline",
      {
        opacity: 0,
        xPercent: 100,
        ease: "power2.out",
      },
      {
        opacity: 1,
        xPercent: 0,
        scrollTrigger: {
          trigger: ".left-statue-top",
          start: "30% bottom",
          end: "40% 60%",
          scrub: true,
        },
      }
    );
  });

  return (
    <div className='w-screen h-auto relative left-statue-top'>
      <div className='flex space-x-0 items-end left-0 top-0 scale-[.8] mt-[12rem] -ml-[3rem]'>
        {/* have an experience */}
        <div className=' hidden lg:flex flex-col space-y-[3rem] mb-[10rem]'>
          <p className='text-white font-inter text-[10px]  lg:text-sm -rotate-[90deg]'>
            Have an experience of the STT
          </p>
          <img
            src={experienceBtn}
            alt='experience-btn'
            className='scale-[.4] cursor-pointer'
            onClick={() => dispatch(scrollToEl())}
          />
        </div>
        <div className='flex space-x-[.5rem] lg:space-x-[9.5rem] items-end relative'>
          {/* left statue */}
          <img
            src={leftStatue}
            alt='left-statue'
            className='left-statue w-[50%] lg:w-full pl-5 lg:pl-0'
          />
          {/* map ouline */}
          <div className='hidden mb-[6%] ml-[10%] relative  md:[80%] lg:w-full md:block'>
            <img
              src={mapOutline}
              alt='map-outline'
              className='map-outline w-[35%] md:w-[55%] lg:w-full'
            />
            <div className='flex items-center absolute top-[34.5%] left-[16%] md:left-[37%] w-full space-x-2'>
              <div className='pulser grid place-content-center w-[16px] h-[14px] lg:w-[60px] lg:h-[60px] bg-themeOrange cursor-pointer top-[25%] left-0'></div>
              <p className='text-white font-koh-santepheap text-[12px] lg:text-sm w-full'>
                Sujimoto Twin Towers
              </p>
            </div>
            <div className='flex items-center absolute bottom-[20%] left-[16%] md:left-[37%] w-full space-x-4'>
              <div className='pulser-circle grid place-content-center w-[7px] h-[7px] lg:w-[30px] lg:h-[30px] bg-white cursor-pointer lg:top-[25%] left-0'></div>
              <p className='text-white font-koh-santepheap w-full text-[12px] lg:text-sm'>
                Lagos State
              </p>
            </div>
          </div>
        </div>
        <h3 className='absolute bottom-[5%] -right-[10%] text-white text-center font-luxurious-script text-[4rem] w-[30%]  lg:text-start md:text-4xl lg:text-6xl'>
          Nestled in Africa’s Fastest Growing Coastal City
        </h3>
      </div>
      <UniqueKeyFacts />
    </div>
  );
};

export default LeftStatue;
