import React, { useLayoutEffect, useRef } from "react";
import "./App.css";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import LongSection from "./components/LongSection/LongSection";
import StickyButton from "./components/StickyButton/StickyButton";

import gsap from "gsap";

function App({ elRef }) {
  const home = gsap.timeline();
  const homeRef = useRef(null);
  const ballRef = useRef(null);

  useLayoutEffect(() => {
    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };

    const mouse = { x: pos.x, y: pos.y };
    const speed = 0.2;

    const xSet = gsap.quickSetter(ballRef.current, "x", "px");
    const ySet = gsap.quickSetter(ballRef.current, "y", "px");

    window.addEventListener("mousemove", (e) => {
      mouse.x = e.x;
      mouse.y = e.y;
    });

    gsap.ticker.add(() => {
      // adjust speed for higher refresh monitors
      const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

      pos.x += (mouse.x - pos.x) * dt;
      pos.y += (mouse.y - pos.y) * dt;
      xSet(pos.x);
      ySet(pos.y);
    });
  });

  return (
    <div ref={homeRef}>
      <div className='ball hidden lg:block' ref={ballRef}></div>
      <StickyButton elRef={elRef} />
      <Hero />
      <LongSection />
      <Footer />
    </div>
  );
}

export default App;
